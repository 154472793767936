import firebase from "firebase/app";
const secondaryAppConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY_FALCONI,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN_FALCONI,
  databaseURL: process.env.VUE_APP_FB_DB_URL_FALCONI,
  projectId: process.env.VUE_APP_FB_PROJECT_ID_FALCONI,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET_FALCONI,
  appId: process.env.VUE_APP_FB_APP_ID_FALCONI,
};
const secondary = firebase.initializeApp(secondaryAppConfig, "secondary");

export default secondary;
