<template>
  <button :disabled="disabled">Request For Quote</button>
</template>

<script>
export default {
  name: "FormButton",
  props: { disabled: Boolean },
};
</script>

<style scoped>
button {
  color: #fff;
  cursor: pointer;
  margin: 20px 0px;
  font-weight: 600;
  font-size: 1.5em;
  height: 60px;
  background: rgba(235, 88, 48, 0.75);
  border: 5px solid rgba(247, 147, 30, 0.75);
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 5px 5px 5px rgba(247, 147, 30, 0.25),
    -5px -4px 10px rgba(247, 147, 30, 0.5);
}
button:hover:enabled {
  filter: brightness(110%);
}
button:active {
  filter: brightness(80%);
  transform: translateY(4px);
}
button:disabled {
  background: rgb(120 120 120 / 75%);
  border: 5px solid rgb(165 165 165 / 25%);
  box-shadow: 5px 5px 5px rgb(138 138 138 / 25%),
    -5px -4px 10px rgb(51 51 51 / 50%);
  pointer-events: none;
}
</style>
