<template>
  <div id="file">
    <span>{{ name }}</span>
    <span class="size">{{ bytesToSize(size) }}</span>
    <img @click="remove(name)" src="@/assets/img/delete.svg" alt="Delete" />
  </div>
</template>

<script>
export default {
  name: "File",
  props: {
    name: String,
    size: Number,
  },
  methods: {
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    remove(name) {
      this.$emit("remove", name);
    },
  },
};
</script>

<style scoped>
img {
  height: 15px;
  position: absolute;
  right: 5px;
  z-index: 10;
}
#file {
  background: #f1f4f7;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 8px;
  position: relative;
  padding: 10px;
  margin: 5px;
}
.size {
  color: #6c7b86;
  font-weight: 600;
  font-size: 14px;
  margin: 0 20px;
}
</style>
