<template>
  <div :id="id" class="checkbox-container">
    <!-- :class="{ 'left-margin': needMargin(id) }" -->
    <!-- v-show="id !== 's7' && id !== 's8'" -->
    <img @click="toggleCheck()" :src="checkImg" :alt="name" class="checkbox" />

    <!-- <img
      v-show="isInstallationSelected && id === 's7'"
      @click="toggleCheck()"
      :src="
        isNewConstructionSelected
          ? require('../assets/img/check-on.svg')
          : require('../assets/img/check-off.svg')
      "
      :alt="name"
      class="checkbox"
    />

    <img
      v-show="isInstallationSelected && id === 's8'"
      @click="toggleCheck()"
      :src="
        isExistingConstructionSelected
          ? require('../assets/img/check-on.svg')
          : require('../assets/img/check-off.svg')
      "
      :alt="name"
      class="checkbox"
    /> -->

    <span>{{ name }}</span>
  </div>
</template>

<script>
const assets = require.context("@/assets/img/", false, /\.svg$/);

export default {
  name: "FormCheckbox",
  props: {
    id: String,
    name: String,
    // isNewConstructionSelected: Boolean,
    // isExistingConstructionSelected: Boolean,
    // isInstallationSelected: Boolean,
  },
  data() {
    return {
      checkImg: assets("./check-off.svg"),
    };
  },
  methods: {
    // needMargin(id) {
    //   // Existing Construction & New Construction display when Installation is checked
    //   return id == "s7" || id == "s8";
    // },
    toggleCheck() {
      this.checkImg =
        this.checkImg == assets("./check-off.svg")
          ? assets("./check-on.svg")
          : assets("./check-off.svg");
      this.$emit("toggleCheckbox", this.name);
    },
  },
};
</script>

<style scoped>
img {
  width: 35px;
}
span {
  color: #6c7b86;
  font-weight: 600;
  font-size: 15px;
  margin-left: 10px;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  width: 32%;
}
.left-margin {
  margin-left: 55px;
  min-width: 50%;
}
@media screen and (max-width: 1024px) {
  .left-margin {
    width: auto !important;
  }
}
@media screen and (max-width: 1400px) {
  .left-margin {
    width: 90px;
  }
}
@media screen and (max-width: 475px) {
  .checkbox-container {
    width: min-content;
  }
}
</style>
