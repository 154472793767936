<template>
  <div id="image-show">
    <MyButton
      title="Prior"
      icon="back"
      @clicked="prev"
      width="50px"
      style="margin: auto"
    />
    <div id="flicking-wrapper" @click="$emit('open-link')">
      <Flicking
        id="flicking"
        :options="flickingOptions"
        :plugins="plugins"
        @changed="handleIndexChange"
        ref="flicking"
      >
        <img
          class="flicking-panel"
          v-for="img in images"
          :key="img"
          :src="getImageUrl(img)"
          alt="Device"
        />
        <!-- <img
          class="flicking-panel"
          src="@/assets/fire-alarm/1.png"
          alt="Device"
        />

        <img
          class="flicking-panel"
          src="@/assets/fire-alarm/2.png"
          alt="Device"
        />
        <img
          class="flicking-panel"
          src="@/assets/fire-alarm/3.png"
          alt="Device"
        />
        <img
          class="flicking-panel"
          src="@/assets/fire-alarm/4.png"
          alt="Device"
        />
        <img
          class="flicking-panel"
          src="@/assets/fire-alarm/5.png"
          alt="Device"
        /> -->
      </Flicking>
    </div>
    <MyButton
      title="Next"
      icon="next"
      @clicked="next"
      width="50px"
      style="margin: auto"
    />
  </div>
</template>

<script>
import MyButton from "./MyButton.vue";
import { Flicking } from "@egjs/vue-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import { Perspective } from "@egjs/flicking-plugins";

export default {
  name: "MyFlicking",

  components: { Flicking, MyButton },

  props: { images: Array },

  data() {
    return {
      currentIndex: 0,
      plugins: [
        new AutoPlay({ duration: 4000, direction: "NEXT", stopOnHover: false }),
        new Perspective({ rotate: 0 }),
      ],
      flickingOptions: {
        align: "center",
        horizontal: true,
        circular: true,
        deceleration: 0.0005,
        panelsPerView: 3,
      },
    };
  },

  created() {},

  mounted() {},

  computed: {},

  watch: {},

  methods: {
    getImageUrl(path) {
      return require("../assets/" + path);
    },
    handleIndexChange({ index }) {
      this.currentIndex = index;
    },

    next() {
      this.$refs.flicking.next();
    },

    prev() {
      this.$refs.flicking.prev();
    },
  },
};
</script>

<style scoped>
#image-show {
  display: flex;
  width: 64%;
  box-shadow: 8px 8px 10px 0px rgb(0 0 0 / 25%);
  padding: 10px;
}

#flicking-wrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
  flex: 1;
  cursor: default;
  /* height: 380px; */
}
.card-panel {
  /* height: 335px; */
  width: 33%;
  border-radius: 15px;
}

.flicking-panel {
  object-fit: contain;
}

@media screen and (max-width: 480px) {
  #image-show {
    width: fit-content;
  }
}
</style>
