<template>
  <div style="display: flex; justify-content: center">
    <div :title="title">
      <div
        class="fsr-button"
        :class="{
          disableClick: disabled,
          disableColor: myDisableColor,
        }"
        @click="$emit('clicked')"
      >
        <img
          :src="require(`@/assets/${icon}.svg`)"
          alt="Navigate"
          :width="width"
          :height="height"
        />

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyButton",

  components: {},

  props: {
    disabled: { type: Boolean, default: false },
    disableColor: {
      type: Boolean,
      default: false,
    },
    icon: String,
    title: String,
    width: { type: String, default: "70px" },
    height: { type: String, default: "70px" },
  },

  data() {
    return { myDisableColor: this.disabled };
  },

  mounted() {},

  computed: {},

  watch: {
    disabled(val) {
      if (!val) this.myDisableColor = false;
      if (val) this.myDisableColor = this.disableColor || val;
    },
  },

  methods: {},
};
</script>

<style scoped>
@media screen and (max-width: 720px) {
  .fsr-button img {
    width: 20px;
    height: 20px;
  }
}
</style>
