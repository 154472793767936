<template>
  <div class="tabs">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="tab"
      :class="{ active: active - 1 == index }"
      @click="navigate(index)"
    >
      <span class="tab-text">{{ tab }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavTabs",
  props: {
    tabs: Array,
    active: Number,
  },
  methods: {
    navigate(index) {
      this.$emit("changePage", index + 1);
    },
  },
};
</script>

<style>
.active {
  background: #ff1313 !important;
  color: #fff;
}
.tabs {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  margin: 0 20px;
  /* height: 100%; */
}

.tab:first-child {
  margin-top: 0;
}

.tab {
  background: #fff;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  width: 100px;
  margin: 5px;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  height: 30px;
  text-align: center;
}
.tab:hover {
  filter: brightness(110%);
}
</style>
