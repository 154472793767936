<template>
  <div id="drop-area">
    <h4 v-if="!files.length">
      Drop Plans, Docs or Photos (No file size limitations)
      <p><small>or click here to browse</small></p>
    </h4>
    <div id="files">
      <file
        @remove="removeFile($event)"
        v-for="(file, index) in files"
        :key="index"
        :name="file.name"
        :size="file.size"
        id="file"
      />
    </div>
  </div>
</template>

<script>
import File from "./File.vue";

export default {
  name: "DropArea",
  components: { File },
  props: {
    files: [],
  },
  methods: {
    removeFile(name) {
      this.$emit("remove", name);
    },
  },
};
</script>

<style scoped>
h4 {
  color: #6c7b86;
  text-align: center;
  margin-top: 40px;
}
#drop-area {
  cursor: pointer;
  background: #d4ecff;
  /* border: 1px dashed #66aee7; */
  box-sizing: border-box;
  height: 150px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: inset 8px 8px 10px #72b9f0;
}
#files {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
}
#file {
  height: fit-content;
}
</style>
