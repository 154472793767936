<template>
  <div class="container">
    <div id="gallery-container">
      <loader v-if="loading" />
      <div class="pages">
        <div class="pages-and-bottom-tabs">
          <div
            id="container"
            v-show="pageNumber !== 14"
            class="pages-container"
            v-viewer="options"
            :class="{ 'pages-container-for-map': pageNumber === 4 }"
          >
            <img
              key="1"
              v-if="pageNumber === 1"
              src="@/assets/home.jpg"
              class="image fade-in-image"
            />
            <img
              id="service"
              key="2"
              v-if="pageNumber === 2"
              src="@/assets/service.jpg"
              class="image fade-in-image"
            />
            <img
              key="3"
              v-show="pageNumber === 3"
              src="@/assets/about-us.jpg"
              class="image fade-in-image"
            />
            <my-maps
              key="4"
              v-show="pageNumber === 4"
              :markers="markers"
              class="image fade-in-image map"
            />

            <div
              v-show="pageNumber === 5"
              class="image fade-in-image flicking-and-image-wrapper"
            >
              <img
                class="image-with-flicking"
                key="5"
                src="@/assets/fire-alarm.jpg"
              />
              <div class="flicking-and-logo-wrapper">
                <img id="logo" src="@/assets/logo.png" />
                <MyFlicking :images="fireAlarmImages" />
                <!-- @open-link="openFireAlarmWebsite" -->
              </div>
            </div>

            <img
              key="6"
              v-show="pageNumber === 6"
              src="@/assets/arcs.jpg"
              class="image fade-in-image"
            />

            <div
              v-show="pageNumber === 7"
              class="image fade-in-image flicking-and-image-wrapper"
            >
              <img
                class="image-with-flicking"
                key="5"
                src="@/assets/gas-detection.jpg"
              />
              <div class="flicking-and-logo-wrapper">
                <img id="logo" src="@/assets/logo.png" />
                <MyFlicking :images="gasDetectionImages" />
                <!-- @open-link="openGasWebsite" -->
              </div>
            </div>

            <img
              key="8"
              v-show="pageNumber === 8"
              src="@/assets/monitoring.jpg"
              class="image fade-in-image"
            />
            <img
              key="9"
              v-show="pageNumber === 9"
              src="@/assets/mit.jpg"
              class="image fade-in-image"
            />
            <img
              key="10"
              v-show="pageNumber === 10"
              src="@/assets/installation.jpg"
              class="image fade-in-image"
            />
            <img
              key="11"
              v-show="pageNumber === 11"
              src="@/assets/equipment.jpg"
              class="image fade-in-image"
            />
            <img
              key="12"
              v-show="pageNumber === 12"
              src="@/assets/engineering-and-design.jpg"
              class="image fade-in-image"
            />
            <img
              key="13"
              v-show="pageNumber === 13"
              src="@/assets/project-management.jpg"
              class="image fade-in-image"
            />
            <img
              key="15"
              v-show="pageNumber === 15"
              src="@/assets/contact.jpg"
              class="image fade-in-image"
            />
            <!-- <pdf
            v-show="!loading && !pageLoading && pageNumber < 15"
            :src="pdf"
            :page="pageNumber"
            @num-pages="pageCount = $event"
            @page-loaded="pageNumber = $event"
            @loaded="finishLoading()"
          ></pdf> -->
          </div>
          <quote-request-form v-show="pageNumber == 14" class="fade-in-image" />

          <div class="tabs-and-empty-wrapper">
            <div class="bottom-tabs">
              <div class="buttons">
                <nav-button
                  :disabled="pageNumber == 1"
                  @click.native="prev"
                  :name="'./back'"
                  :alt="'previous page'"
                  tooltipText="Back"
                  key="1"
                />
                <nav-button
                  @click.native="navigate(1)"
                  :name="'./home'"
                  :alt="'first page'"
                  tooltipText="Home"
                  :class="{ disabled: pageNumber === 1 }"
                  key="2"
                />
                <nav-button
                  @click.native="navigate(14)"
                  :name="'./quote'"
                  :alt="'Request For Quote'"
                  tooltipText="Request For Quote"
                  :class="{ disabled: pageNumber === 14 }"
                  key="3"
                />
                <nav-button
                  :disabled="pageNumber == 15"
                  @click.native="next"
                  :name="'./next'"
                  :alt="'next page'"
                  tooltipText="Forward"
                  key="4"
                />
              </div>
              <div class="hl"></div>
              <span class="copyright">
                Copyright © 2021-{{ new Date().getFullYear() }} EZ Fire. All
                rights reserved.
              </span>
            </div>

            <!-- <div class="empty-div">
            <div class="float" @click="openSkype" title="Chat with us on Skype">
            <img src="@/assets/chat.svg" class="my-float" />
          </div>
        </div> -->
          </div>
        </div>

        <!-- v-show="pageNumber === 16" -->
        <nav-tabs
          v-show="!loading"
          id="nav-tabs"
          :tabs="tabs"
          :active="pageNumber"
          @changePage="navigate($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MyFlicking from "./MyFlicking.vue";
import MyMaps from "./MyMaps.vue";
import Loader from "./Loader.vue";
import NavButton from "./NavButton.vue";
import QuoteRequestForm from "./QuoteRequestForm.vue";
import NavTabs from "./NavTabs.vue";
import secondaryFirebaseProject from "@/helpers/secondaryProject";
// const rootAssets = require.context("@/assets/", false, /\.pdf$/);

export default {
  name: "Gallery",
  components: {
    // Flicking,
    NavButton,
    QuoteRequestForm,
    Loader,
    NavTabs,
    MyMaps,
    MyFlicking,
  },
  data() {
    return {
      // pdf: rootAssets("./ezfire.pdf"),
      pageCount: 0,
      pageNumber: 1,
      pageLoading: true,
      options: {
        title: false,
        toolbar: false,
        navbar: false,
        loop: false,
        movable: false,
        filter(image) {
          return image.alt !== "Navigate" && image.alt !== "Device";
        },
      },
      pages: [
        "home",
        "services",
        "about",
        "we-are-nation-wide",
        "fire-alarm",
        "arcs",
        "gas",
        "monitoring",
        "mit",
        "installation",
        "equipment",
        "engineering",
        "project-management",
        "quote",
        // "shop",
        "contact",
      ],
      loading: true,
      tabs: [
        "HOME",
        "SERVICES",
        "ABOUT US",
        "WE ARE NATIONWIDE",
        "FIRE ALARM",
        "ARCS",
        "GAS",
        "MONITORING",
        "MIT",
        "INSTALLATION",
        "EQUIPMENT",
        "ENGINEERING & DESIGN",
        "PROJECT MANAGEMENT",
        "REQUEST FOR QUOTE",
        // "SHOP",
        "CONTACT US",
      ],

      fireAlarmImages: [
        "fire-alarm/1.png",
        "fire-alarm/2.png",
        "fire-alarm/3.png",
        "fire-alarm/4.png",
        "fire-alarm/5.png",
      ],
      gasDetectionImages: [
        "gas/1.png",
        "gas/2.png",
        "gas/3.png",
        "gas/4.png",
        "gas/5.png",
      ],
      markers: [],
    };
  },
  mounted() {
    this.getMapMarkers();
    try {
      const page = this.resolvePathIndex(this.$route.params.page);
      this.navigate(page < 0 ? 1 : page + 1, false);
    } catch (error) {
      console.error(error.message);
    }
  },

  methods: {
    // openSkype() {
    //   window.open("skype:-skype-id-?chat", "_self");
    // },

    openFireAlarmWebsite() {
      window.open(
        "https://www.shop.e-zfirecontrols.com/Fire-Alarm-Equipment_c104.htm",
        "_tab"
      );
    },

    openGasWebsite() {
      window.open(
        "https://www.shop.e-zfirecontrols.com/Gas-Detection-Equipment_c107.htm",
        "_tab"
      );
    },

    getMapMarkers() {
      secondaryFirebaseProject
        .auth()
        .signInAnonymously()
        .then(() => {
          secondaryFirebaseProject
            .firestore()
            .collection("projects")
            .get()
            .then((snapshot) => {
              const projects = snapshot.docs
                .map((el) => ({
                  ...el.data(),
                  coors: {
                    lat: el.data().lat ? parseFloat(el.data().lat) : "",
                    lng: el.data().lng ? parseFloat(el.data().lng) : "",
                  },
                  projectAddress: el.data().addr,
                }))
                .filter((el) => !el.isTestSite && !el.disabled);

              this.markers = projects.map((el) => ({
                position: {
                  lat: el.lat ? el.lat : parseFloat(el.coors.lat),
                  lng: el.lng ? el.lng : parseFloat(el.coors.lng),
                },
                infoText: `PROJECT#${el.id} ${el.projectName} @ ${el.projectAddress}`,
                id: el.id,
                icon: "https://outercloud-icons.s3.us-west-2.amazonaws.com/pin.svg",
              }));
            })
            .catch((error) => console.error(error));
        });
    },

    navigate(number, redirect = true) {
      // if (number == 15)
      // return window.open("https://www.shop.e-zfirecontrols.com/", "_tab");
      this.pageNumber = number;
      this.pageLoading = true;
      this.finishLoading();
      if (redirect) this.updatePath();
    },
    next() {
      if (this.pageNumber == 15) return;
      this.pageNumber++;
      this.pageLoading = true;
      this.finishLoading();
      if (this.pageNumber == 14) this.pageNumber = 15;
      this.updatePath();
    },
    prev() {
      if (this.pageNumber == 1) return;
      this.pageNumber--;
      this.pageLoading = true;
      this.finishLoading();
      if (this.pageNumber == 15) this.pageNumber = 14;
      this.updatePath();
    },
    finishLoading() {
      setTimeout(() => {
        this.loading = false;
        this.pageLoading = false;
      }, 500);
    },
    resolvePath() {
      return this.pages[this.pageNumber - 1];
    },
    resolvePathIndex(pageName) {
      return this.pages.indexOf(pageName);
    },
    updatePath() {
      this.$router.push({ path: `/${this.resolvePath(this.pageNumber)}` });
    },
  },
};
</script>

<style scoped>
.float {
  display: flex;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: rgb(250, 248, 240);
  color: #fff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  cursor: pointer;
  z-index: 10;
}

.my-float {
  margin: auto;
  cursor: pointer;
}

.fade-in-image {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.disabled {
  filter: grayscale(100%);
}

#gallery-container {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 75%;
  justify-content: center;
}
.container {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hl {
  border-top: 1px solid #d1d9e6;
  width: 330px;
  margin: 0px auto 5px;
}
.copyright {
  font-style: italic;
  font-size: 12px;
  opacity: 0.7;
  text-align: center;
}
.buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.loader {
  width: 90%;
}
.gallery-page {
  border: 1px solid #e9ecef;
  margin: 0 25px;
}
.pages {
  align-items: center;
  display: flex;
  height: 90%;
  /* width: 100%; */
}
.pages span {
  height: 100%;
  text-align: center;
  width: 100%;
}
.pages-container {
  /* background: #fff;
  box-shadow: 8px 8px 10px 0px rgb(0 0 0 / 25%); */
  /* height: 88%; */
  /* width: 100%; */
  height: 800px;
  width: 1000px;
  max-height: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}
.image {
  height: 790px;
  max-height: 100%;
  width: 100%;
  /* height: 88%; */
  object-fit: contain;
  background: #fff;
  box-shadow: 8px 8px 10px 0px rgb(0 0 0 / 25%);
  /* max-width: 1024px; */
  /* max-height: 750px; */
  /* height: fit-content; */
}

.map {
  flex: 1;
  max-width: 1024px;
  height: 790px;
  max-height: 100%;
  width: 100%;
  /* height: 100%; */
  /* min-height: 600px;
  min-width: 47vw;
  max-width: 906px;
  max-height: 750px; */
}

.image-with-flicking {
  width: 100%;
}

.bottom-tabs {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.empty-div {
  flex: 1;
}
.tabs-and-empty-wrapper {
  margin-top: 20px;
  display: flex;
  /* width: 100%; */
}
.quote {
  object-fit: cover;
  /* max-height: 100%; */
  /* height: 900px; */
}

.flicking-and-logo-wrapper {
  background: white;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  height: 164px;
}
.flicking-and-image-wrapper {
  /* height: fit-content; */
  /* margin: 0px 0px 35px; */
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
  margin: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.pages-and-bottom-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

@media screen and (min-height: 124px) {
  .tabs {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .tabs {
    flex-direction: unset;
    flex-wrap: wrap;
    width: 50%;
  }

  /* .flicking-and-image-wrapper {
    width: 500px;
  } */
}
@media screen and (max-width: 1200px) {
  #gallery-container {
    width: 100%;
  }
  .tabs {
    display: none;
  }

  .empty-div {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .gallery-page {
    width: 95%;
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .pages-container {
    height: auto !important;
    min-height: 300px;
    width: 100% !important;
  }

  #form-container {
    height: auto !important;
    min-height: 300px;
    width: 100% !important;
  }

  .image {
    width: 100%;
  }
  .flicking-and-logo-wrapper {
    height: 145px;
  }
  .image-with-flicking {
    max-width: 760px;
  }

  .pages-and-bottom-tabs {
    justify-content: center;
  }

  .map {
    width: 100vw;
    /* max-height: 500px; */
  }
}

@media screen and (max-width: 724px) {
}
@media screen and (min-width: 1240px) {
  .gallery-page {
    height: 85%;
  }
}

/* @media screen and (min-height: 1240px) {
  #nav-tabs {
  margin-top: 40px;
}
} */

@media screen and (max-height: 760px) {
  .flicking-and-logo-wrapper {
    height: 120px;
  }
}

@media screen and (max-width: 720px) {
  /* #gallery-container {
    height: 80%;
  } */
  .pages-container {
    height: auto;
  }

  .map {
    height: 433px !important;
  }

  .image {
    height: 100%;
  }
  .flicking-and-image-wrapper {
    height: auto;
  }
}

@media screen and (max-width: 360px) {
  .map {
    height: 329px !important;
  }

  .image {
    height: 329px !important;
  }

  #form-container {
    width: 100vw !important;
  }
}

@media screen and (max-width: 720px) {
  /* #gallery-container {
    height: 80%;
  } */

  .pages-container-for-map {
    /* height: 100%;
    max-height: 40vh;
    margin: 5px; */
  }

  /* #gallery-container {
    height: auto;
  } */
}

/* @media screen and (max-width: 972px) {
  .container {
    height: auto;
  }
} */
</style>
