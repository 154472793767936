<template>
    <img src="@/assets/img/404.svg" alt="Not Found">
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
img {
    height: 100%;
}
</style>
