<template>
  <div>
    <img class="logo" :src="falconiLogo" alt="EZ Fire" />
    <div v-if="files.length" id="files" class="files-list">
      <div class="file" v-for="(file, index) in files" :key="index">
        <div class="file-name-container">
          <span class="file-name">{{ file.name }}</span>
          <span v-if="file.size" class="secondary-text file-size">{{
            file.size
          }}</span>
        </div>
        <div class="s-download">
          <button
            class="s-download-btn"
            :disabled="isDownloadingAll"
            @click="download(file.name, file.path)"
          >
            <img src="@/assets/img/arrow.svg" />
          </button>
          <div class="s-download-gradient"></div>
          <div class="s-download-border"></div>
        </div>
      </div>
    </div>
    <div v-if="files.length" class="s-download" style="margin-top: 20px">
      <button
        class="download-all"
        :disabled="isDownloadingAll"
        @click="downloadAll()"
      >
        {{ isDownloadingAll ? "Downloading..." : "Download All" }}

        <img src="@/assets/img/arrow.svg" style="margin-left: 10px" />
      </button>
    </div>

    <div v-if="invalid" class="not-found">
      <not-found />
    </div>
  </div>
</template>

<script>
import JSZip from "jszip";
import axios from "axios";
import firebase from "firebase/app";
import NotFound from "./NotFound.vue";
import "firebase/storage";
import "firebase/auth";
import secondaryFirebaseProject from "@/helpers/secondaryProject";
export default {
  name: "Downloads",
  components: {
    NotFound,
  },
  data() {
    return {
      files: [],
      invalid: false,
      falconiLogo: "",
      isDownloadingAll: false,
    };
  },
  destroyed() {
    firebase.auth().currentUser.delete();
  },
  mounted() {
    this.getDataFromFalconi();

    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        // Get list of files from storage
        // Create a reference under which you want to list
        var listRef = firebase.storage().ref().child(this.$route.params.id);

        // Find all the prefixes and items.
        listRef
          .listAll()
          .then((res) => {
            if (!res.items.length) this.invalid = true;
            res.items.forEach((itemRef) => {
              // All the items under listRef.
              this.files.push({
                name: itemRef.name,
                path: itemRef.fullPath,
              });
            });
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            this.$swal.fire({
              text: "Failed to get files, please refresh the page again",
              icon: "error",
            });
            console.error(error.message);
          });
      });
  },
  methods: {
    async downloadAll() {
      this.isDownloadingAll = true;
      console.log(this.files);
      const zip = new JSZip();
      await Promise.all(
        this.files.map(async ({ path, name }, index) => {
          const url = await this.getDownloadURL(path);
          const response = await this.axiosDownload(url, index);
          zip.file(name, response.data);
        })
      );

      // Generate the zip file and initiate the download
      const zipData = await zip.generateAsync({ type: "blob" });
      const urlObject = URL.createObjectURL(zipData);

      this.downloadViaHref(urlObject, "files.zip");
      this.isDownloadingAll = false;
    },

    downloadViaHref(urlObject, name) {
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlObject);
    },

    getDownloadURL(filePath) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signInAnonymously()
          .then(() => {
            firebase
              .storage()
              .ref()
              .child(filePath)
              .getDownloadURL()
              .then((url) => {
                resolve(url);
              })
              .catch((error) => {
                reject(error);
              });
          });
      });
    },

    axiosDownload(url) {
      return axios({
        url,
        method: "GET",
        responseType: "blob",
      });
    },

    getDataFromFalconi() {
      secondaryFirebaseProject
        .auth()
        .signInAnonymously()
        .then(() => {
          secondaryFirebaseProject
            .firestore()
            .collection("settings")
            .doc("headerAndFooterSettings")
            .get()
            .then((doc) => {
              this.falconiLogo = doc.data().falconiLogo.downloadURL;
            });
        });
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    saveFile(blob, fileName) {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },

    download(fileName, filePath) {
      let self = this;
      firebase
        .auth()
        .signInAnonymously()
        .then(() => {
          firebase
            .storage()
            .ref()
            .child(filePath)
            .getDownloadURL()
            .then((url) => {
              // `url` is the download URL for 'images/stars.jpg'

              // This can be downloaded directly:
              var xhr = new XMLHttpRequest();
              xhr.responseType = "blob";
              xhr.onload = () => {
                var blob = xhr.response;
                self.saveFile(blob, fileName);
              };
              xhr.open("GET", url);
              xhr.send();
            })
            .catch((error) => {
              // Handle any errors
              this.$swal.fire({
                text: "Failed to download, please try again",
                icon: "error",
              });
              console.error(error.message);
            });
        });
    },
  },
};
</script>

<style scoped>
button:focus {
  outline: none;
}
#files {
  align-items: center;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
.file {
  background: #f1f4f7;
  border-radius: 8px;
  box-shadow: 8px 8px 10px rgba(159, 166, 173, 0.15),
    -4px -4px 15px rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  padding: 20px;
  text-transform: uppercase;
  width: 40%;
  margin-top: 15px;
}

.files-list {
  height: 600px;
  overflow: auto;
}

.file-size {
  opacity: 0.5;
}

.file img {
  height: 40px;
  width: 40px;
}

.file-name {
  color: #636971;
  font-size: 1.2em;
  font-weight: 900;
}

.file-name-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 80%;
}
.secondary-text {
  color: #636971;
}
.s-download {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.s-download-btn {
  background: #dde4ec;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 45px;
  width: 45px;
  position: absolute;
  z-index: 2;

  transition: all 0.2s ease-out;
}

.s-download-btn img {
  height: 30px;
  padding-top: 5px;
  width: 30px;
}

.s-download-btn:hover {
  transform: scale(0.9);
}

.s-download-btn:active {
  opacity: 0.5;
}

.s-download-border {
  background: #e2e9f1;
  border-radius: 50%;
  filter: blur(0.06px);
  width: 60px;
  height: 60px;
}

.s-download-gradient {
  background: linear-gradient(147.48deg, #ffffff 6.11%, #c4d9ed 88.42%);
  border-radius: 50%;
  filter: blur(1px);
  height: 52px;
  width: 52px;
  position: absolute;
  z-index: 1;
}
.logo {
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  width: 170px;
}

.download-all {
  margin-top: 40px;
  margin-right: 20px;
  background: #dde4ec;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  height: 45px;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  font-weight: 900;
  text-transform: uppercase;
  color: #636971;
  transition: all 0.2s ease-out;
  padding: 10px;
}

.s-download-btn,
.download-all:hover {
  transform: scale(0.9);
}

.s-download-btn:disabled,
.download-all:disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media screen and (max-width: 1280px) {
  .file {
    height: 30px;
    width: 80%;
  }

  .file-size {
    font-size: 12px;
  }

  .file-name {
    font-size: 0.7em;
  }
}
</style>
