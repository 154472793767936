<template>
  <div id="form-container" ref="formContainer">
    <div id="form">
      <Flicking
        :options="{
          align: 'center',
          bound: true,
          horizontal: true,
          circular: true,
          circularFallback: 'bound',
          deceleration: 0.0005,
          useFractionalSize: true,
        }"
        :plugins="plugins"
        style="width: 295px"
        id="flicking"
      >
        <img
          class="quote"
          src="@/assets/img/price-quote1.png"
          alt="Request For Quote | Call Us | (866) 347-3736"
        />

        <img
          class="quote"
          src="@/assets/img/price-quote2.png"
          alt="Request For Quote | Call Us | (866) 347-3736"
        />

        <img
          class="quote"
          src="@/assets/img/price-quote3.png"
          alt="Request For Quote | Call Us | (866) 347-3736"
        />

        <img
          class="quote"
          src="@/assets/img/price-quote4.png"
          alt="Request For Quote | Call Us | (866) 347-3736"
        />

        <img
          class="quote"
          src="@/assets/img/price-quote5.png"
          alt="Request For Quote | Call Us | (866) 347-3736"
        />

        <img
          class="quote"
          src="@/assets/img/price-quote6.png"
          alt="Request For Quote | Call Us | (866) 347-3736"
        />

        <img
          class="quote"
          src="@/assets/img/price-quote7.png"
          alt="Request For Quote | Call Us | (866) 347-3736"
        />

        <img
          class="quote"
          src="@/assets/img/price-quote8.png"
          alt="Request For Quote | Call Us | (866) 347-3736"
        />
      </Flicking>
      <form @submit.prevent="checkForm()">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 20px 10px 20px;
          "
        >
          <img
            id="rfq"
            src="@/assets/img/rfq.svg"
            alt="Request for Price Quote | Call Us | (866) 347-3736"
            title="Request for Price Quote | Call Us | (866) 347-3736"
            width="100px"
          />
          <div class="vl"></div>
          <img
            id="quote-mini"
            src="@/assets/img/price-quote-title.svg"
            alt="Request For Quote | Call Us | (866) 347-3736"
          />
        </div>
        <!-- <p>Your information will NOT be shared with third parties!</p> -->
        <div class="flex-inputs">
          <div class="falcon-input-container">
            <input
              style="flex: 1"
              v-model="email"
              type="email"
              class="falcon-input shrink"
              placeholder=" "
              v-debounce="findDataByEmail"
              @input="isUserInDB = false"
              :required="!email || !isValidEmail() || isGettingDataFromDB"
            />
            <!-- @input="isGettingDataFromDB = true" -->
            <label class="falcon-input-block__label">Your Email</label>
          </div>

          <div class="falcon-input-container">
            <input
              style="flex-grow: 1; text-transform: capitalize"
              v-model="firstName"
              type="text"
              class="falcon-input shrink"
              placeholder=" "
              :required="!firstName"
              :disabled="!email || !isValidEmail() || isGettingDataFromDB"
            />
            <label class="falcon-input-block__label">First Name</label>
          </div>

          <div class="falcon-input-container">
            <input
              style="flex-grow: 1; text-transform: capitalize"
              v-model="lastName"
              type="text"
              class="falcon-input"
              placeholder=" "
              :required="!lastName"
              :disabled="!email || !isValidEmail() || isGettingDataFromDB"
            />
            <label class="falcon-input-block__label">Last Name</label>
          </div>
        </div>

        <div class="flex-inputs">
          <div class="falcon-input-container">
            <input
              style="flex-grow: 1; text-transform: capitalize"
              v-model="company"
              type="text"
              placeholder=" "
              class="falcon-input"
              :disabled="!email || !isValidEmail() || isGettingDataFromDB"
            />
            <label class="falcon-input-block__label">Company Name</label>
          </div>

          <div class="falcon-input-container">
            <input
              style="flex: 1; text-transform: capitalize"
              v-model="title"
              type="text"
              placeholder=" "
              class="falcon-input"
              :required="company.length > 0 && !title"
              :disabled="!email || !isValidEmail() || isGettingDataFromDB"
            />
            <label class="falcon-input-block__label">Title</label>
          </div>

          <div class="flex-inputs" id="phoneWithExt">
            <div class="falcon-input-container" style="flex: 1.8; min-width: 0">
              <imask-input
                style="flex: 2; min-width: 0"
                v-model="phone"
                placeholder=" "
                class="falcon-input"
                :mask="mask"
                :unmask="false"
                :required="!phone || phone.length !== 14"
                :disabled="!email || !isValidEmail() || isGettingDataFromDB"
              />
              <label class="falcon-input-block__label">Phone Number</label>
            </div>
            <div class="falcon-input-container" style="flex: 1; min-width: 0">
              <input
                style="flex: 1; min-width: 0"
                class="falcon-input not-required"
                v-model="extension"
                name="extension"
                type="number"
                placeholder=" "
                :disabled="!email || !isValidEmail() || isGettingDataFromDB"
              />
              <label class="falcon-input-block__label">Ext.</label>
            </div>
          </div>

          <!-- <div class="falcon-input-container" style="flex: 1; ">
            <input
              style="flex: 1;"
              class="falcon-input"
              v-model="projectAddress"
              name="projectAddress"
              type="text"
              placeholder=" "
            />
            <label class="falcon-input-block__label">Project Address</label>
          </div> -->
        </div>

        <h4 style="margin: 10px">Select the services you are interested in</h4>
        <div id="services">
          <form-checkbox
            @toggleCheckbox="toggleCheck($event)"
            v-for="service in formServices"
            :id="service.id.toString()"
            :key="service.id"
            :name="service.name"
          />
          <!-- :isInstallationSelected="insterests.includes('Installation')"
            :isNewConstructionSelected="isNewConstructionSelected"
            :isExistingConstructionSelected="isExistingConstructionSelected" -->
        </div>
        <div style="margin: 10px; display: flex">
          <textarea
            v-model="comments"
            rows="5"
            placeholder="Add Your Message"
            :required="insterests.includes('Other') && !comments"
          />
        </div>
        <div
          class="drop-area-container"
          :class="{ highlight: dragging }"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <input
            id="fileInput"
            type="file"
            class="file-input"
            @change="this.onFileChange"
            multiple
          />
          <drop-area @remove="removeFile($event)" :files="filesToUpload" />
        </div>
        <loader v-if="loading" style="margin-top: 10px" />
        <div style="margin: 10px">
          <div id="footer">
            <vue-recaptcha
              @verify="captchaResponse()"
              @expired="handleExpired"
              :sitekey="reCaptchaKey"
              :loadRecaptchaScript="true"
              id="recaptcha"
              ref="recaptcha"
            ></vue-recaptcha>
            <div id="security">
              <img
                id="security1"
                src="@/assets/img/security1.svg"
                alt="Your information will NOT be shared!"
                title="Your information will NOT be shared!"
                width="100px"
              />
              <img
                id="security2"
                src="@/assets/img/security2.svg"
                alt="Your files are safely encrypted."
                title="Your files are safely encrypted."
                width="100px"
              />
            </div>
          </div>
        </div>
        <form-button
          id="submit"
          v-if="showButton"
          :disabled="!isHuman || !isValidated()"
        />
      </form>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import DropArea from "./DropArea.vue";
import FormButton from "./FormButton.vue";
import FormCheckbox from "./FormCheckbox.vue";
import Loader from "./Loader.vue";
import { IMaskComponent } from "vue-imask";
import { Flicking } from "@egjs/vue-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/auth";
const assets = require.context("@/assets/img/", false, /\.svg$/);
const plugins = [
  new AutoPlay({ duration: 4000, direction: "NEXT", stopOnHover: false }),
];
import secondaryFirebaseProject from "@/helpers/secondaryProject";

export default {
  name: "QuoteRequestForm",
  components: {
    FormCheckbox,
    DropArea,
    FormButton,
    Loader,
    "imask-input": IMaskComponent,
    VueRecaptcha,
    Flicking,
  },
  data() {
    return {
      plugins,
      mask: "(000) 000-0000",
      firstName: "",
      lastName: "",
      title: "",
      company: "",
      email: "",
      phone: "",
      extension: "",
      // projectAddress: "",
      comments: "",
      insterests: [],
      timestamp: "",
      services: [
        { id: "s1", name: "Central Station Monitoring" },
        { id: "s2", name: "Maintenance & Service" },
        { id: "s3", name: "Equipment & Commissioning" },
        { id: "s4", name: "Design, File & Expedite" },
        { id: "s5", name: "Violations Removal" },
        { id: "s6", name: "Installation" },
        { id: "s7", name: "New System" },
        { id: "s8", name: "Modification To Existing System" },
        { id: "s9", name: "Other" },
      ],
      formServices: [],
      errors: [],
      dragging: false,
      filesToUpload: [],
      data: [],
      loading: false,
      isHuman: false,
      showButton: false,
      reCaptchaKey: process.env.VUE_APP_RECAPTCHA,
      footerHTML: "",
      falconiLogo: "",
      isGettingDataFromDB: false,
      isUserInDB: false,
    };
  },
  destroyed() {
    if (firebase.auth().currentUser != null)
      firebase.auth().currentUser.delete();
  },
  mounted() {
    this.initFormServices();
    this.getDataFromFalconi();
  },
  computed: {
    makeRequired() {
      return this.company.length > 0;
    },
    isNewConstructionSelected() {
      return this.insterests.includes("New Construction");
    },
    isExistingConstructionSelected() {
      return this.insterests.includes("Existing Construction");
    },
  },
  methods: {
    getDataFromFalconi() {
      secondaryFirebaseProject
        .auth()
        .signInAnonymously()
        .then(() => {
          secondaryFirebaseProject
            .firestore()
            .collection("settings")
            .doc("headerAndFooterSettings")
            .get()
            .then((doc) => {
              this.footerHTML = doc.data().footerHTML
                ? doc
                    .data()
                    .footerHTML.replace("$lastYear", new Date().getFullYear())
                : "";
              this.falconiLogo = doc.data().falconiLogo.downloadURL;
            });
        });
    },
    captchaResponse() {
      this.showButton = true;
      this.isHuman = true;
      setTimeout(() => {
        document
          .getElementById("submit")
          .scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
    handleExpired() {
      this.isHuman = false;
    },
    initFormServices() {
      this.formServices = this.services.map((s) => {
        return {
          id: s.id,
          name: s.name,
        };
      });
      // setTimeout(() => {
      //   this.toggleInstallation(false);
      // }, 100);
    },
    fireFutureSaveAlert() {},
    async findDataByEmail(value) {
      if (this.isValidEmail(value)) {
        try {
          await firebase.auth().signInAnonymously();
        } catch (error) {
          console.error("error in signning in", error);
        }

        const snap = await firebase
          .firestore()
          .collection("quotes")
          .where("email", "==", value)
          .orderBy("timestamp", "desc")
          .get();
        if (snap.docs.length) {
          const doc = snap.docs[0].data();
          this.firstName = doc.firstName;
          this.lastName = doc.lastName;
          this.title = doc.title;
          this.company = doc.company;
          this.email = doc.email;
          this.phone = doc.phoneNumber;
          this.extension = doc.phoneExtension;

          this.isUserInDB = true;
        } else {
          this.isUserInDB = false;
          this.$swal.fire({
            icon: "info",
            text: "The credentials you enter will be saved for your next visit",
            heightAuto: false,
          });
        }

        this.isGettingDataFromDB = false;
      }
    },
    putStorageItem(folder, item) {
      // get file blob
      let file;
      for (let index = 0; index < this.data.length; index++) {
        const element = this.data[index];
        if (item.name == element.name) {
          file = element;
        }
      }
      if (file == null) return;
      return firebase
        .storage()
        .ref()
        .child(`${folder}/${item.name}`)
        .put(file)
        .then(() => {})
        .catch((error) => {
          console.log("Failed to upload:", file.name, error.message);
        });
    },
    checkForm() {
      if (!this.isHuman) return;
      this.errors = [];
      if (!this.firstName) this.errors.push("firstName");
      if (!this.lastName) this.errors.push("lastName");
      if (this.company && !this.title) this.errors.push("title");
      if (!this.email) this.errors.push("email");
      if (!this.phone) this.errors.push("phone");
      if (this.errors.length) return;
      if (this.insterests.length == 0) {
        this.$swal.fire({
          icon: "info",
          text: "Please select at least one of the services provided",
          heightAuto: false,
        });
        return;
      }
      // Proceed sending the data
      let quote = {
        folder: this.filesToUpload.length ? Date.now() : "",
        firstName: this.firstName,
        lastName: this.lastName,
        title: this.title,
        company: this.company,
        email: this.email,
        phoneNumber: this.phone,
        phoneExtension: this.extension,
        // projectAddress: this.projectAddress,
        message: this.comments,
        services: this.insterests.toString().split(",").join("/ "),
        files: this.filesToUpload,
        footerHTML: this.footerHTML,
        falconiLogo: this.falconiLogo,
      };

      firebase
        .auth()
        .signInAnonymously()
        .then(() => {
          this.loading = true;
          // Upload files to Firestore
          Promise.all(
            this.filesToUpload.map((file) =>
              this.putStorageItem(quote.folder, file)
            )
          )
            .then(() => {
              // Create Quote and Send Email
              let createQuote = firebase
                .functions()
                .httpsCallable("createQuote");
              createQuote(quote)
                .then(() => {
                  this.reset();
                  this.$swal
                    .fire({
                      title: "Request For Quote sent!",
                      html: "We'll get back to you as soon as possible",
                      icon: "success",
                      heightAuto: false,
                    })
                    .then(() => {
                      this.$swal.fire({
                        title: "Bookmark this page?",
                        html:
                          "Press " +
                          (navigator.userAgent.toLowerCase().indexOf("mac") !=
                          -1
                            ? "Command/Cmd"
                            : "CTRL") +
                          " + D to bookmark this page for future requests.<br><br>" +
                          (this.isUserInDB
                            ? "Thank you for returning!"
                            : "Next time, just use your email. We got you!"),
                        icon: "success",
                        heightAuto: false,
                      });
                    });
                })
                .catch((error) => {
                  console.error(error);
                  this.$swal.fire({
                    title: "Error",
                    html: "Please refresh the page and try again!",
                    icon: "error",
                    heightAuto: false,
                  });
                })
                .finally(() => {
                  this.loading = false;
                });
            })
            .catch((error) => {
              console.error(error.message);
              this.loading = false;
              this.$swal.fire({
                title: "Error",
                html: "Please refresh the page and try again!",
                icon: "error",
                heightAuto: false,
              });
            });
        });
    },
    toggleCheck(service) {
      if (this.insterests.indexOf(service) > -1) {
        this.insterests.splice(this.insterests.indexOf(service), 1);

        // this service is in interests, remove it and add the other
        // if (service === "New Construction")
        //   this.insterests.push("Existing Construction");
        // else if (service === "Existing Construction")
        //   this.insterests.push("New Construction");

        // if (service === "Installation") {
        //   this.toggleInstallation(false);
        //   this.isExistingConstructionSelected &&
        //     this.insterests.splice(
        //       this.insterests.indexOf("Existing Construction"),
        //       1
        //     );
        //   this.isNewConstructionSelected &&
        //     this.insterests.splice(
        //       this.insterests.indexOf("New Construction"),
        //       1
        //     );
        // }
      } else {
        // this service is not in interests, remove the other  and add this
        // if (service === "New Construction") {
        //   this.insterests.splice(
        //     this.insterests.indexOf("Existing Construction"),
        //     1
        //   );
        // } else if (service === "Existing Construction") {
        //   this.insterests.splice(
        //     this.insterests.indexOf("New Construction"),
        //     1
        //   );
        // }

        this.insterests.push(service);
        // if (service === "Installation") {
        //   this.toggleInstallation(true);
        //   this.insterests.push("New Construction");
        // }
      }
    },
    // toggleInstallation(visible) {
    //   let display = visible ? "flex" : "none";
    //   document.getElementById("s7").style.display = display;
    //   document.getElementById("s8").style.display = display;
    // },
    hasError(service) {
      return this.errors.indexOf(service) > -1;
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.dragging = false;

      Array.from(files).forEach((file) => {
        if (this.filesToUpload.some((el) => el.name === file.name)) {
          this.$swal.fire({
            icon: "info",
            text: "This file is duplicated: \n" + file.name,
            heightAuto: false,
          });
        } else {
          this.data.push(file);
          this.filesToUpload.push({
            name: file.name,
            size: file.size,
          });
        }
      });
    },
    removeFile(name) {
      this.filesToUpload = this.filesToUpload.filter((f) => f.name !== name);
      if (!this.filesToUpload.length)
        document.getElementById("fileInput").value = "";
    },
    reset() {
      this.firstName = "";
      this.lastName = "";
      this.title = "";
      this.company = "";
      this.email = "";
      this.phone = "";
      this.phone = "";
      this.extension = "";
      // this.projectAddress = "";
      this.comments = "";
      this.timestamp = "";
      this.errors = [];
      this.filesToUpload = [];
      this.data = [];
      this.resetCheckboxes();
      this.initFormServices();
      this.insterests = [];
      document.getElementById("fileInput").value = "";

      this.$refs.recaptcha.reset();
    },
    resetCheckboxes() {
      this.formServices.forEach((service) => {
        let checkImg = document.getElementById(service.id).firstElementChild; // img
        let img = checkImg.src.replace(window.location.origin, ""); // clean img src path
        if (img == assets("./check-on.svg")) checkImg.click(); // toggle off the ones checked
      });
    },
    isValidEmail() {
      const rgxEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

      return rgxEmail.test(this.email);
    },
    isValidated() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.isValidEmail() ||
        !this.phone ||
        // !this.projectAddress ||
        (this.insterests.includes("Other") && !this.comments)
      ) {
        return false;
      } else return true;
    },
  },
};
</script>

<style scoped>
@import url("../../node_modules/@egjs/vue-flicking/dist/flicking.css");
@import url("../../node_modules/@egjs/flicking-plugins/dist/flicking-plugins.css");

.falcon-input:focus ~ .falcon-input-block__label,
.falcon-input:not(:placeholder-shown) ~ .falcon-input-block__label {
  top: 14px;
  font-size: 10px;
  line-height: 12px;
}
.falcon-input-block__label {
  color: #757575;
  font-weight: 500;
  font-size: 13px;
  left: 20px;
  line-height: 21px;
  pointer-events: none;
  position: absolute;
  text-shadow: -1px -1px 2px rgb(236 247 255 / 50%),
    1px 1px 1px rgb(0 0 0 / 25%);
  top: 26px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.falcon-input-container {
  position: relative;
  flex-grow: 1;
  display: flex;
}

h3 {
  color: rgb(151, 151, 151);
  font-style: italic;
  text-align: center;
  margin: 0px;
  margin-top: 15px;
}
p,
h4 {
  color: #6c7b86;
  text-align: center;
}
input,
textarea {
  box-shadow: inset 8px 8px 10px #bdceeb;
  height: 32px;
  border: none;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  font-weight: 600;
  color: #1a1a1a;
}
textarea {
  width: 100%;
  height: auto;
  resize: none;
  overflow: auto;
  margin-right: 0;
  margin-left: 0;
  margin-top: 15px;
}
textarea:required {
  box-shadow: inset 8px 8px 10px #fb9ba8;
}
input:required {
  box-shadow: inset 8px 8px 10px #fb9ba8;
}
input:focus,
textarea:focus {
  outline: none;
}
p {
  font-size: 12px;
}
form {
  display: flex;
  flex-direction: column;
  background: #eaecf0;
  padding: 0 5%;
  overflow: auto;
  width: 70%;
  overflow-x: hidden;
}
#container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
#form-container {
  background: #fff;
  width: 1000px;
  height: 790px;
  max-height: 100%;
  box-shadow: 8px 8px 10px 0px rgb(0 0 0 / 25%);
}
#form {
  max-height: 96%;
  max-width: 100%;
  background: #eaecf0;
  display: flex;
  margin: 1em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px 20px;
}
#recaptcha {
  width: unset !important;
  margin: auto;
  margin-bottom: 10px;
}
#services {
  display: flex;
  flex-direction: column;
  height: 215px;
  flex-wrap: wrap;
  width: 95%;
}
#phoneWithExt {
  flex: 1;
  flex-wrap: nowrap;
  min-width: 33.4%;
}
.quote {
  object-fit: cover;
  width: 100%;
  /* max-height: 100%; */
  /* height: 900px; */
}

#quote-mini {
  display: none;
  height: 50px;
  object-fit: contain;
}
.drop-area-container {
  position: relative;
  margin: 10px;
}
#drop-area {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-inputs {
  display: flex;
  flex-wrap: wrap;
}
.file-input {
  cursor: pointer;
  width: 96%;
  height: 88%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 1;
}
.highlight {
  filter: brightness(110%);
}
.loader {
  width: 100%;
}
.not-required {
  box-shadow: inset 8px 8px 10px #bdceeb !important;
}
.vl {
  display: none;
  height: 100%;
  margin: 10px;
  border-right: 2px solid #d8d8d9;
}
#security {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
}

#security1 {
  margin: auto;
}

@media screen and (max-width: 433px) {
  .shrink {
    min-width: 85%;
  }
}

@media screen and (max-width: 540px) {
  #form-container {
    margin-top: 25px;
  }
  #quote-mini {
    display: inline;
  }
  .vl {
    display: inline;
  }
}
@media screen and (max-width: 1024px) {
  .quote {
    display: none;
  }
  #services {
    flex-direction: column !important;
    height: auto !important;
    width: 60% !important;
  }
}
@media screen and (max-width: 670px) {
  #services {
    flex-direction: row !important;
    height: auto !important;
    width: auto !important;
  }
}
@media screen and (max-width: 1660px) {
  #services {
    flex-direction: row;
    height: auto;
    width: auto;
  }
  #services > .checkbox-container {
    width: 100%;
  }

  #footer {
    flex-direction: column;
  }

  #security {
    margin: 10px 0px;
    width: 100%;
  }

  #security1 {
    margin: unset;
  }
}
@media screen and (min-width: 1660px) {
  #services {
    flex-direction: column;
    height: 215px;
    width: 95%;
  }
}
@media screen and (max-height: 824px) and (max-width: 1554px) {
  .shrink {
    min-width: 85%;
  }
}
@media screen and (max-height: 824px) {
  #services {
    flex-direction: row !important;
    height: auto !important;
    width: auto !important;
  }
  #services > .checkbox-container {
    width: 100%;
  }
}
@media screen and (max-width: 512px) {
  #phoneWithExt {
    flex: unset;
  }
}
@media screen and (max-width: 1420px) {
  input[name="extension"] {
    flex-grow: 1;
  }

  #flicking {
    display: none;
  }
  form {
    width: 100%;
  }
}
@media screen and (min-height: 1200px) {
  #form-container {
    /* height: auto; */
  }
}
</style>
