<template>
  <div class="tooltip">
    <img :src="buttonImage()" :alt="alt" @blur="hidden = false" />
    <span v-if="!hidden" class="tooltiptext" :id="name" @click="toggle">
      {{ tooltipText }}
    </span>
  </div>
</template>

<script>
const assets = require.context("@/assets/img", false, /\.svg$/);

export default {
  name: "NavButton",
  props: {
    alt: String,
    name: String,
    disabled: Boolean,
    tooltipText: String,
  },
  data() {
    return { hidden: false };
  },
  methods: {
    buttonImage() {
      return this.disabled
        ? assets(`${this.name}-disabled.svg`)
        : assets(`${this.name}.svg`);
    },
    toggle() {
      this.hidden = !this.hidden;
      console.log("hidden", this.hidden);
    },
  },
};
</script>

<style scoped>
img {
  margin: 0 10px;
  width: 70px;
}
img:hover {
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  position: absolute;
  z-index: 1;
  bottom: 80px;
  left: 8px;
  transition: opacity 1.5s ease-in-out;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  filter: alpha(opacity=50);
  opacity: 0;
}

@media screen and (max-width: 600px) {
  img {
    height: 80%;
  }
}
</style>
