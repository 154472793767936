<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import { version } from "../package.json";
export default {
  name: "App",
  metaInfo: {
    title: "EZ Fire | Your Go To Fire Life Safety Professionals",
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      {
        name: "copyright",
        content:
          "All product names, logos, and brands are property of their respective owners. All company, product, and service names used are for identification purposes only. Use of these names, logos, and brands does not imply endorsement.",
      },
      {
        name: "description",
        content:
          "Authorized dealer and integrator of premier life safety equipment. Solutions to fit many types of premises and architectures.",
      },
      {
        name: "keywords",
        content:
          "fire alarm installation,fire alarm maintenance,fire alarm repair,fire alarm violation,fire alarm testing,fire alarm inspection,fire alarm sales,fire alarm system,life safety equipment",
      },
      { property: "og:title", content: "EZ Fire" },
      { property: "og:site_name", content: "EZ Fire" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      {
        property: "og:url",
        content:
          "https://www.linkedin.com/company/ez-firecontrols-distributors-inc-",
      },
      {
        property: "og:image",
        content:
          "https://outercloud-icons.s3.us-west-2.amazonaws.com/ezfire-logo.png",
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "Authorized dealer and integrator of premier life safety equipment. Solutions to fit many types of premises and architectures.",
      },
    ],
    linkInfo: [{ rel: "canonical", href: "https://ezfire.us/" }],
    changed(metaInfo) {
      firebase.analytics().setCurrentScreen(metaInfo.title);
      firebase.analytics().logEvent("page_view");
      firebase.analytics().logEvent("screen_view", {
        app_name:
          window && window.matchMedia("(display-mode: standalone)").matches
            ? "pwa"
            : "web",
        screen_name: metaInfo.title,
        app_version: version,
      });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
html {
  margin: 0px;
  padding: 0px;
  height: 100vh;
}
body {
  background: #edf2f4;
  font-family: "Roboto", sans-serif;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}
canvas {
  height: 100% !important;
  object-fit: contain;
}
#app {
  height: 100%;
}

.fsr-button {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}
</style>
