import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase/app";
import "firebase/analytics";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueMeta from "vue-meta";
import VueAnalytics from "vue-analytics";
import Debounce from "vue-debounce";
import * as VueGoogleMaps from "vue2-google-maps";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS,
    libraries: "places",
    region: "US",
    language: "en",
  },
  installComponents: false,
});
Vue.component("google-map", VueGoogleMaps.Map);
Vue.component("google-marker", VueGoogleMaps.Marker);
Vue.component("google-info-window", VueGoogleMaps.InfoWindow);
Vue.config.productionTip = false;

Vue.use(Viewer);
Vue.use(VueSweetalert2);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VueAnalytics, {
  id: "UA-228117629-1",
  router,
});

Vue.use(Debounce, {
  listenTo: ["input", "keyup"],
  defaultTime: "1000ms",
});

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DB_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDERLD,
  appId: process.env.VUE_APP_FB_APP_ID,
  measurementId: process.env.VUE_APP_FB_MEASSUREMENT_ID,
});
firebase.analytics();

// alias
Vue.prototype.$analytics = firebase.analytics();

if (process.env.VUE_APP_ENV === "dev")
  firebase.functions().useEmulator("localhost", "4000");

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
