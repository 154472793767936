import Vue from "vue";
import VueRouter from "vue-router";
import Gallery from "./components/Gallery";
import Downloads from "./components/Downloads";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:page?",
      name: "Home",
      component: Gallery,
      meta: {
        title: "EZ Fire",
      },
    },
    {
      path: "/downloads/:id",
      name: "Downloads",
      component: Downloads,
      meta: {
        title: "EZ Fire | Downloads",
      },
    },
  ],
});

export default router;
